@font-face {
  font-family: 'Times-New';
  font-weight: 600;
  src: url('./fonts/times-new.otf');
}

@font-face {
  font-family: 'Univers';
  font-weight: 700;
  src: url('./fonts/univers-next-pro.ttf');
}

body {
  margin: 0;
  font-family: 'Times-New', Helvetica, Arial, sans-serif;
  background-color: #F7F8FC;
}

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}